<template>
    <div class="BodyContainer">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'BodyContainer',
};
</script>

<style scoped>
.BodyContainer {
    width: 100%;
    /* height: 100%; */
    padding: 0 16px;
    background-color: #f7f7f7;
    /* background: red; */
}

</style>